import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";
import CreateModal from "./actions/CreateModal.jsx";

const Announcements = () => {
  const { t } = useTranslation();
  const [showCreateAlert, setShowCreateAlert] = useState(false);

  return (
    <>
      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateAlert(true)}
          >
            إرسال تنبيه
          </Button>
        </Flex>
      </SimpleGrid>

      {showCreateAlert && (
        <CreateModal onClose={() => setShowCreateAlert(false)} />
      )}
    </>
  );
};

export default Announcements;
