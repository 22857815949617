import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Flex,
  Select,
} from "@chakra-ui/react";
import theme from "../../global/theme";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns, view }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, watch } = useForm();
  const [filterType, setFilterType] = useState("day");

  watch("filterType");

  return (
    <form
      onSubmit={handleSubmit((values) => {
        let finalValues = {};

        for (let prop in values) {
          if (values[prop]) {
            if (prop === "from" || prop === "to") {
              finalValues[prop] = new Date(values[prop]);
            } else {
              finalValues[prop] = values[prop];
            }
          }
        }

        handleFilter(finalValues);
      })}
    >
      <SimpleGrid
        columns={{ base: 2, md: 2, lg: 4 }}
        spacing={6}
        bg="#eee"
        p={2}
      >
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            نوع الفلتر
          </FormLabel>
          <Select
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            {...register("filterType")}
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value); // Update the filterType state
              setValue("filterType", e.target.value);
              setValue("from", "");
              setValue("to", "");
            }}
          >
            <option value="day">يومي</option>
            <option value="week">أسبوعي</option>
            <option value="month">شهري</option>
            <option value="other"> نطاق زمني</option>
          </Select>
        </FormControl>
        {view == "adminStatics" && (
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color={theme.dark}
            >
              القسم
            </FormLabel>
            <Select
              bg={theme.bg}
              color={theme.dark}
              border="none"
              borderRadius={4}
              {...register("department")}
            >
              <option value="All">الكل</option>
              <option value="cs.">CS</option>
              <option value="co.">CO</option>
            </Select>
          </FormControl>
        )}
        {filterType === "other" && (
          <>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                (من)تاريخ الإنشاء
              </FormLabel>
              <Input
                type="date"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder="من"
                _placeholder={{ textTransform: "capitalize" }}
                {...register("from")}
              />
            </FormControl>

            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                إلى
              </FormLabel>
              <Input
                type="date"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder="إلى"
                _placeholder={{ textTransform: "capitalize" }}
                {...register("to")}
              />
            </FormControl>
          </>
        )}

        <Flex alignItems="flex-end">
          <Button
            type="submit"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="blue"
            me={2}
          >
            فلترة
          </Button>
          <Button
            type="button"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="red"
            onClick={() => {
              handleClearFilter();
              setValue("from", "");
              setValue("to", "");
              setValue("filterType", "day");
              setValue("department", "All");
              setFilterType("day"); // Reset the filterType state
            }}
          >
            استعادة
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  );
};

export default React.memo(Filter);
