import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  SimpleGrid,
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Link as ChakraLink,
} from "@chakra-ui/react";

import theme from "../../../global/theme";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import {
  addFavoriteProduct,
  removeFavoriteProduct,
  updateProductStatus,
} from "../../../../store/products/productsSlice";
import { useDispatch, useSelector } from "react-redux";

const AvailableList = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const permissions = auth.user?.rule?.permissions ?? [];

  const getTotalQuantity = (item) => {
    let total = 0;
    for (let i = 0; i < item.properties.length; i++) {
      total += item.properties[i]?.value;
    }
    return total;
  };

  return (
    <SimpleGrid
      bg={theme.light}
      boxShadow={theme.shadow}
      columns={{ base: 2, md: 2, lg: 3 }}
      // minChildWidth="170px"
      spacing={{ base: 2, lg: 6 }}
      p={4}
      borderRadius={18}
    >
      {data.map((el) => (
        <Box
          key={el._id}
          border={`1px solid ${theme.border}`}
          borderRadius={18}
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          p={3}
        >
          <Flex
            as={Link}
            to={`/available_products/${el._id}`}
            h={{ sm: "160px", lg: "280px" }}
            borderRadius={18}
            bg="#ededed"
            boxShadow={theme.shadow}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              objectFit="contain"
              maxH={{ sm: "160px", lg: "280px" }}
              fill={true}
              src={`/products/${el.image}`}
            />
          </Flex>
          <Heading
            as="h5"
            color={theme.dark}
            mt={3}
            mb={4}
            fontSize={{ base: 14, lg: 18 }}
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={4}
          >
            {el.name}{" "}
            {!el.isFavorite ? (
              <CiStar
                style={{ cursor: "pointer", color: "red" }}
                size={30}
                onClick={() => {
                  dispatch(addFavoriteProduct({ productId: el._id }));
                }}
              />
            ) : (
              <FaStar
                style={{ cursor: "pointer", color: "green" }}
                size={35}
                onClick={() => {
                  dispatch(removeFavoriteProduct({ productId: el._id }));
                }}
              />
            )}
          </Heading>

          <Flex
            color={theme.dark}
            alignItems="center"
            mt={2}
            fontWeight="500"
            fontSize={{ base: 13, lg: 16 }}
          >
            <Text ms={1} me={4} fontSize={{ base: 13, lg: 16 }}>
              {t("pages.products.barcode")}:{" "}
            </Text>
            <Text>{el.barcode}</Text>
          </Flex>

          <Flex
            color={theme.dark}
            alignItems="center"
            mt={2}
            fontWeight="500"
            fontSize={{ base: 13, lg: 16 }}
          >
            <Text ms={1} me={4} fontSize={{ base: 13, lg: 16 }}>
              الكمية:{" "}
            </Text>
            <Text>{getTotalQuantity(el)}</Text>
          </Flex>

          <Flex
            color={theme.dark}
            alignItems="center"
            mt={2}
            fontWeight="500"
            fontSize={{ base: 13, lg: 16 }}
          >
            <Text ms={1} me={4} fontSize={{ base: 13, lg: 16 }}>
              {t("pages.products.sale_price")}:{" "}
            </Text>
            <Text>{el.sale_price}</Text>
          </Flex>

          <Flex
            color={theme.dark}
            alignItems="center"
            mt={2}
            fontWeight="500"
            fontSize={{ base: 13, lg: 16 }}
          >
            <Flex fontWeight="bold" color={theme.error}>
              <Box display="inline-block" me={3} color={theme.blue}>
                ملاحظة:{" "}
              </Box>
            </Flex>
          </Flex>
          <Flex
            color={theme.dark}
            alignItems="center"
            mt={2}
            fontWeight="500"
            fontSize={{ base: 12, lg: 16 }}
          >
            <Flex color={theme.error}>
              <Box display="inline-block" me={3} color={theme.error}>
                {el?.note}
              </Box>
            </Flex>
          </Flex>
          <Flex
            justifyContent={"space-between"}
            style={{ justifyContent: "space-between" }}
            w="100%"
            alignSelf="start"
            fontSize={{ base: 12, lg: 16 }}
          >
            <Flex w="48%">
              <ChakraLink
                as={Link}
                to={`/available_products/${el._id}`}
                bg="#333054"
                display="block"
                w="100%"
                paddingBlock={2}
                borderRadius="5px"
                textAlign="center"
                color={theme.light}
                mt={6}
                textTransform="capitalize"
              >
                {t("general.details")}
              </ChakraLink>
            </Flex>

            <Flex w="48%">
              <ChakraLink
                href={el.media_url}
                bg={theme.blue}
                display="block"
                w="100%"
                paddingBlock={2}
                borderRadius="5px"
                textAlign="center"
                color={theme.light}
                mt={6}
                textTransform="capitalize"
              >
                {t("pages.products.media_url")}
              </ChakraLink>
            </Flex>
          </Flex>
          {permissions.includes("manage_products") && (
            <Flex
              justifyContent={"space-between"}
              style={{ justifyContent: "space-between" }}
              w="100%"
              alignSelf="start"
              fontSize={{ base: 12, lg: 16 }}
            >
              <Flex w="48%">
                <Flex
                  as="button"
                  bg={el?.is_most_popular ? "#0f0" : "#333054"}
                  display="block"
                  w="100%"
                  paddingBlock={2}
                  borderRadius="5px"
                  textAlign="center"
                  color={theme.light}
                  mt={6}
                  textTransform="capitalize"
                  onClick={() =>
                    dispatch(
                      updateProductStatus({
                        id: el._id,
                        values: { is_most_popular: !el?.is_most_popular },
                      })
                    )
                  }
                >
                  {el?.is_most_popular
                    ? "مضاف من قبل"
                    : "إضافة للمنتجات الأكثر نشاطاً"}
                </Flex>
              </Flex>

              <Flex w="48%">
                <Flex
                  as="button"
                  bg={el?.is_most_recent ? "#0f0" : "#333054"}
                  display="block"
                  w="100%"
                  paddingBlock={2}
                  borderRadius="5px"
                  textAlign="center"
                  color={theme.light}
                  mt={6}
                  textTransform="capitalize"
                  onClick={() =>
                    dispatch(
                      updateProductStatus({
                        id: el._id,
                        values: { is_most_recent: !el?.is_most_recent },
                      })
                    )
                  }
                >
                  {el?.is_most_recent
                    ? "مضاف من قبل"
                    : "إضافة للمنتجات المضافة حديثاً"}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default AvailableList;
