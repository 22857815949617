import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Heading,
  Tfoot,
} from "@chakra-ui/react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import PieChart from "./PieChart";
import { useNavigate } from "react-router-dom";
const DataTable = ({ data }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key === null) return 0;

    const order = sortConfig.direction === "ascending" ? 1 : -1;
    const aValue =
      sortConfig.key === "qtyCount"
        ? a?.statuses.find((item) => item?.status === "skip")?.qtyCount || 0
        : a[sortConfig.key];
    const bValue =
      sortConfig.key === "qtyCount"
        ? b?.statuses.find((item) => item?.status === "skip")?.qtyCount || 0
        : b[sortConfig.key];

    return aValue > bValue ? order : -order;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <FiChevronUp />
      ) : (
        <FiChevronDown />
      );
    }
    return null;
  };

  return (
    <TableContainer>
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("product")}
            >
              المنتج {getSortIcon("product")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("totalOrderCount")}
            >
              عدد الطلبات {getSortIcon("totalOrderCount")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("totalQty")}
            >
              عدد القطع {getSortIcon("totalQty")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("qtyCount")}
            >
              جار الإسترجاع {getSortIcon("qtyCount")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("productPercent")}
            >
              نسبة التسليم {getSortIcon("productPercent")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("productCallCenterQuality")}
            >
              نسبة التأكيد
              {getSortIcon("productCallCenterQuality")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("dailyRate")}
            >
              معدل السحب اليومي
              {getSortIcon("dailyRate")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((item, index) => (
            <Tr
              key={index}
              cursor="pointer"
              onClick={() => {
                navigate(`/available_products/${item?.productId}`);
              }}
            >
              <Td textAlign="center">{item?.product}</Td>
              <Td textAlign="center">{item?.totalOrderCount}</Td>
              <Td textAlign="center">{item?.totalQty}</Td>
              <Td textAlign="center">
                {item?.statuses.find((item) => item?.status === "skip")
                  ?.qtyCount || 0}
              </Td>
              <Td textAlign="center">
                {(item?.productPercent || 0).toFixed(2)}%
              </Td>
              <Td textAlign="center">
                {(item?.productCallCenterQuality || 0).toFixed(2)}%
              </Td>
              <Td textAlign="center">{(item?.dailyRate || 0).toFixed(2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export const MarketersDataTable = ({ data, diviedBy }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const order = sortConfig.direction === "ascending" ? 1 : -1;
    return a[sortConfig.key] > b[sortConfig.key] ? order : -order;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <FiChevronUp />
      ) : (
        <FiChevronDown />
      );
    }
    return null;
  };

  return (
    <TableContainer>
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("product")}
            >
              اسم المسوق
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("totalOrderCount")}
            >
              عدد الطلبات {getSortIcon("totalOrderCount")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("pendingAmount")}
            >
              عمولات محتملة {getSortIcon("pendingAmount")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("collectedAmount")}
            >
              عمولات مؤكدة {getSortIcon("collectedAmount")}
            </Th>

            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("productPercent")}
            >
              نسبة التسليم {getSortIcon("productPercent")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("productCallCenterQuality")}
            >
              نسبة التأكيد
              {getSortIcon("productCallCenterQuality")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((item, index) => (
            <Tr key={index}>
              <Td textAlign="center">{item?.marketer}</Td>
              <Td textAlign="center">{item?.totalOrderCount}</Td>
              <Td textAlign="center">{item?.pendingAmount}</Td>
              <Td textAlign="center">{item?.collectedAmount}</Td>
              <Td textAlign="center">
                {(item?.productPercent || 0).toFixed(2)}%
              </Td>
              <Td textAlign="center">
                {(item?.productCallCenterQuality || 0).toFixed(2)}%
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
const getNestedValue = (obj, keyPath) => {
  return keyPath.split(".").reduce((value, key) => value?.[key], obj);
};

export const AdminDataTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  // Sort data based on the current sortConfig
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key === null) return 0;

    const order = sortConfig.direction === "ascending" ? 1 : -1;
    const aValue = getNestedValue(a, sortConfig.key);
    const bValue = getNestedValue(b, sortConfig.key);

    // Handle sorting for string values
    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * order;
    }

    // Default sorting for other types (numbers, etc.)
    return (aValue > bValue ? 1 : -1) * order;
  });

  // Request sorting by a specific key
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Get the appropriate sort icon for the column
  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <FiChevronUp />
      ) : (
        <FiChevronDown />
      );
    }
    return null;
  };

  return (
    <TableContainer>
      <Table variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("user")}
            >
              اسم الموظف {getSortIcon("user")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("totalActions")}
            >
              عدد الأنشطة {getSortIcon("totalActions")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("categories.ordersActions")}
            >
              إجراءات الطلبات {getSortIcon("categories.ordersActions")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("categories.ordersNotes")}
            >
              ملاحظات الطلبات {getSortIcon("categories.ordersNotes")}
            </Th>
            <Th
              fontSize={14}
              cursor="pointer"
              textAlign="center"
              onClick={() => requestSort("categories.requestsActions")}
            >
              طلبات السحب {getSortIcon("categories.requestsActions")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((item, index) => (
            <Tr key={index}>
              <Td textAlign="center">{item?.user}</Td>
              <Td textAlign="center">{item?.totalActions}</Td>
              <Td textAlign="center">{item?.categories?.ordersActions || 0}</Td>
              <Td textAlign="center">{item?.categories?.ordersNotes || 0}</Td>
              <Td textAlign="center">
                {item?.categories?.requestsActions || 0}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export const ActivityTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    data?.totalCount > 0 && (
      <Box p={4}>
        <Heading size="md" mb={4}>
          نظرة عامة على {t(data?.category)}
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>إجمالي العدد {data?.totalCount}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td textAlign="center">
                {" "}
                <PieChart statuses={data?.statuses || []} t={t} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    )
  );
};
export default DataTable;
