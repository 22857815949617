import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import MerchantsAccountsTable from "./MerchantsAccountsTable";
import Filter from "./actions/Filter";

import { getAccounts } from "../../../store/accounts/accountsSlice";

import { MerchantsAccountsWrapper } from "./MerchantsAccountsStyle";
import OpenModal from "../users/actions/OpenModal";
import theme from "../../global/theme";
import UpdateAccount from "../marketersAccounts/actions/UpdateAccount";
import formatNumber from "../../../utilities/formatNumber";

const MerchantsAccounts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts);
  const [showUpdateAccount, setShowUpdateAccount] = useState(false);
  const [account, setAccount] = useState(null);
  const [showOpenUser, setShowOpenUser] = useState(false);
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(1);
  const defaultFilter = { role: "merchant" };
  const [filter, setFilter] = useState(JSON.stringify(defaultFilter));

  useEffect(() => {
    if (!showUpdateAccount) {
      setAccount(null);
    }
  }, [showUpdateAccount]);

  useEffect(() => {
    dispatch(getAccounts({ page, query: "", filter, sort }));
  }, [dispatch, page, filter, sort]);

  return (
    <MerchantsAccountsWrapper>
      <Breadcrumbs
        currentPage={t("pages.accounts.accounts")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {accounts.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {accounts.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} mb={8} spacing={6}>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg="#d7b323"
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <MdIcons.MdAttachMoney size={40} /> {/* Dollar Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.pendingBalance) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              ارصدة معلقه
            </Text>
          </Box>
        </Flex>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg="#009ef7"
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <AiIcons.AiOutlineClockCircle size={40} /> {/* Clock Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.preparingBalance) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              ارصدة جار التحضير
            </Text>
          </Box>
        </Flex>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg="#f1416c"
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <FaIcons.FaBoxOpen size={40} /> {/* Box Open Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.shippedBalance) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              ارصدة تم الشحن
            </Text>
          </Box>
        </Flex>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg={theme.primary}
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <AiIcons.AiOutlineWallet size={40} /> {/* Wallet Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.availableBalance) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              أرصدة تم التوصيل
            </Text>
          </Box>
        </Flex>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg={theme.gary1}
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <BiIcons.BiMoney size={40} /> {/* Money Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.availableToWithdrawBalance) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              ارصدة قابلة للسحب
            </Text>
          </Box>
        </Flex>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg={theme.blue}
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <MdIcons.MdShoppingCart size={40} /> {/* Shopping Cart Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.totalPurchasePrice) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              إجمالي المبيعات
            </Text>
          </Box>
        </Flex>
        <Flex
          boxShadow={theme.shadow}
          borderRadius={10}
          bg={theme.success}
          padding={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between">
            <Box color={theme.light}>
              <MdIcons.MdDoneAll size={40} /> {/* Done All Icon */}
            </Box>
          </Flex>
          <Box>
            <Text
              fontWeight="bold"
              fontSize={{ base: 18, md: 25, lg: 30 }}
              mt={4}
              color={theme.light}
            >
              <span style={{ color: theme.light }}>
                {formatNumber(accounts.data?.totalDoneRequests) || "-"}
              </span>
            </Text>
            <Text
              fontWeight="bold"
              fontSize={{ base: 13, md: 14, lg: 16 }}
              textTransform="capitalize"
              color={theme.light}
            >
              أرصدة تم سحبها
            </Text>
          </Box>
        </Flex>
      </SimpleGrid>

      <Filter
        handleFilter={(values) =>
          setFilter(JSON.stringify({ ...values, ...defaultFilter }))
        }
        handleClearFilter={() => setFilter(JSON.stringify(defaultFilter))}
        disablesBtns={accounts.isLoading}
      />

      {accounts.data?.itemsCount > 0 ? (
        <MerchantsAccountsTable
          data={accounts.data.data}
          page={page}
          sort={sort}
          setSort={setSort}
          setAccount={setAccount}
          setShowUpdateAccount={setShowUpdateAccount}
          handleOpen={(el) => setShowOpenUser(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.accounts.no_accounts")}
          </Text>
        </Flex>
      )}
      {showUpdateAccount && (
        <UpdateAccount
          data={account}
          isMerchant={true}
          onClose={() => {
            setShowUpdateAccount(false);
            setAccount(null);
            dispatch(getAccounts({ page, query: "", filter, sort }));
          }}
        />
      )}
      {showOpenUser && (
        <OpenModal data={showOpenUser} onClose={() => setShowOpenUser(false)} />
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={accounts.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </MerchantsAccountsWrapper>
  );
};

export default MerchantsAccounts;
