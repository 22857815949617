import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import TableToExcel from "table-to-excel";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
  Select,
  Box,
  Spinner,
} from "@chakra-ui/react";

import { FiPercent } from "react-icons/fi";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import OrdersTable from "./OrdersTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import UpdateShippingCompanyModal from "./actions/UpdateShippingCompanyModal";
import OrderStatusModal from "./actions/OrderStatusModal";
import UpdateOrdersStatusModal from "./actions/UpdateOrdersStatusModal";
import CancelOrdersStatusModal from "./actions/CancelOrdersStatusModal";
import Filter from "./actions/Filter";

import { getOrders } from "../../../store/orders/ordersSlice";

import { OrdersWrapper } from "./OrdersStyle";

import theme from "../../global/theme";
import InputSearch from "../../shared/inputSearch/InputSearch";
import OrderStatusCard, { orderStatuses } from "./OrderStatusCard";

const tableToExcel = new TableToExcel();

const Orders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders);
  const auth = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const createOrderBtnRef = useRef();
  const fullTableRef = useRef(null);

  const page = +searchParams.get("page") || 1;
  const size = +searchParams.get("size") || 20;
  const query = searchParams.get("query") || "";
  const queryPhone = searchParams.get("queryPhone") || "";
  const [currentStatus, setCurrentStatus] = useState(
    searchParams.get("status") || ""
  );

  const [filter, setFilter] = useState(JSON.stringify({})); // {});
  const [additionalParams, setAdditionalParams] = useState({});

  const [showCreateOrder, setShowCreateOrder] = useState(false);
  const [showUpdateOrder, setShowUpdateOrder] = useState(false);
  const [showUpdateOrdersShippingCompany, setShowUpdateOrdersShippingCompany] =
    useState(false);
  const [showOrderStatus, setShowOrderStatus] = useState(false);
  const [showCancelOrdersStatus, setShowCancelOrdersStatus] = useState(false);

  const [updateOrders, setUpdateOrders] = useState([]);
  const [showUpdateOrdersStatus, setShowUpdateOrdersStatus] = useState(false);
  const [showReverseOrdersStatus, setShowReverseOrdersStatus] = useState(false);

  useEffect(() => {
    if (
      searchParams.get("open_modal") &&
      searchParams.get("open_modal") === "true"
    ) {
      createOrderBtnRef.current.click();
      searchParams.delete("open_modal");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    // Using object spread to conditionally add 'status' property
    searchParams.set("status", currentStatus);
    setSearchParams(searchParams);
    const filter = JSON.stringify({
      ...(additionalParams || {}),
      ...(currentStatus ? { status: currentStatus } : {}),
    });

    setFilter(filter);
  }, [currentStatus, additionalParams]);

  useEffect(() => {
    // searchParams.set("page", 1);
    // setSearchParams(searchParams);

    setUpdateOrders([]);
  }, [currentStatus]);

  useEffect(() => {
    const controller = new AbortController();
    console.log(filter);
    dispatch(getOrders({ page, query, queryPhone, size, filter, controller }));
    return () => controller.abort();
  }, [dispatch, page, query, queryPhone, size, filter, showCreateOrder]);

  return (
    <OrdersWrapper>
      <Breadcrumbs
        currentPage={t("pages.orders.orders")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {orders.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {orders.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      {orders.isLoading ? (
        <Flex padding={6} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <SimpleGrid
          columns={{ base: 2, lg: 2, xl: 6 }}
          //minChildWidth="180px"
          spacing={4}
          mb={8}
        >
          {orderStatuses.map(
            ({ key, status, bgColor, iconColor, title, icon }) => (
              <OrderStatusCard
                key={key}
                status={status}
                page={page}
                size={size}
                query={query}
                queryPhone={queryPhone}
                dataKey={key}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
                iconColor={iconColor}
                title={title}
                orders={orders}
                filterLabel="انقر للفلترة"
                bgColor={bgColor}
                icon={icon}
              />
            )
          )}

          <Flex
            boxShadow={theme.shadow}
            bg={theme.success}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex justifyContent="space-between">
              <Box color={theme.light}>
                <FiPercent size={40} />
              </Box>
            </Flex>
            <Box>
              <Text
                fontWeight="bold"
                fontSize={{ base: 25, lg: 30 }}
                mt={4}
                color={theme.light}
              >
                <span style={{ color: theme.light }}>
                  {orders.data?.percent?.toFixed(2) ?? "-"}
                </span>
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                textTransform="capitalize"
                color={theme.light}
              >
                نسبة التسليم
              </Text>
            </Box>
          </Flex>

          {(auth.user.role === "admin" || auth.user.role === "marketer") && (
            <Flex
              boxShadow={theme.shadow}
              bg={theme.success}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex justifyContent="space-between">
                <Box color={theme.light}>
                  <FiPercent size={40} />
                </Box>
              </Flex>
              <Box>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 25, lg: 30 }}
                  mt={4}
                  color={theme.light}
                >
                  <span style={{ color: theme.light }}>
                    {orders.data?.callCenterQuality?.toFixed(2) ?? "-"}
                  </span>
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 14, lg: 16 }}
                  textTransform="capitalize"
                  color={theme.light}
                >
                  جودة العملاء ونسبة التأكيد
                </Text>
              </Box>
            </Flex>
          )}
        </SimpleGrid>
      )}

      <SimpleGrid columns={{ base: 1, md: 2 }} justifyContent="space-between">
        <Flex mb={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={{ base: 15, lg: 18 }}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateOrder(true)}
            ref={createOrderBtnRef}
          >
            انشاء طلب +
          </Button>

          <Button
            type="button"
            variant="outline"
            onClick={() => {
              // document.querySelectorAll(".removable").forEach((el) => {
              //   el.remove();
              // });
              //  const table = document.getElementById(
              //    "table-to-xls_orders"
              //  );
              //  if (!table) {
              //    console.error("Table not found!");
              //    return;
              //  }

              //  // Convert the HTML table to a worksheet
              //  const worksheet = XLSX.utils.table_to_sheet(table);

              //  // Create a new workbook
              //  const workbook = XLSX.utils.book_new();

              //  // Append the worksheet to the workbook
              //  XLSX.utils.book_append_sheet(
              //    workbook,
              //    worksheet,
              //    "Orders"
              //  );

              //  // Write the workbook to an .xlsx file
              //  XLSX.writeFile(workbook, "orders.xlsx");
              tableToExcel.render("table-to-xls_orders", {
                name: "orders.xlsx",
              });
            }}
          >
            استخراج شيت اكسل
          </Button>
        </Flex>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 2, md: 2, lg: 2 }}>
        <Flex marginBottom={8} alignItems="center" justifyContent="flex-end">
          <InputSearch
            placeholder="ابحث عن طلباتك"
            isLoading={orders.isLoading}
            handleSearch={(query) => {
              searchParams.set("query", query);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
        <Flex marginBottom={8} alignItems="center" justifyContent="flex-end">
          <InputSearch
            placeholder="بحث برقم الهاتف"
            isLoading={orders.isLoading}
            handleSearch={(query) => {
              searchParams.set("queryPhone", query);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
      </SimpleGrid>

      <Filter
        handleFilter={(values) => {
          setAdditionalParams((prev) => {
            return { ...prev, ...values };
          });
        }}
        handleClearFilter={() => {
          setAdditionalParams({});
        }}
        disablesBtns={orders.isLoading}
      />

      {updateOrders.length > 0 && (
        <Flex justifyContent="flex-end">
          {auth.user?.rule?.permissions?.includes("manage_orders") &&
            auth.user?.rule?.name === "superadmin" &&
            auth.user?.role === "admin" && (
              <Button
                variant="outline"
                type="button"
                marginLeft={4}
                ms={4}
                color={theme.error}
                borderColor={theme.error}
                onClick={() => {
                  setShowReverseOrdersStatus(true);
                }}
              >
                إرجاع حالة الطلبات
              </Button>
            )}

          <Button
            bg="red.600"
            mb={6}
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={{ base: 15, lg: 18 }}
            color="white"
            _hover={{ bg: "green.600" }}
            onClick={() => setShowUpdateOrdersStatus(true)}
          >
            تحديث حالة الطلبات
          </Button>

          <Button
            bg="blue.600"
            mb={6}
            textTransform="uppercase"
            ms={4}
            fontWeight="bold"
            fontSize={{ base: 15, lg: 18 }}
            color="white"
            _hover={{ bg: "blue.600" }}
            onClick={() => setShowUpdateOrdersShippingCompany(true)}
          >
            شركة الشحن
          </Button>
        </Flex>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Pagination
          page={+page > orders.data?.pages ? 1 : page}
          size={+size}
          itemsCount={orders.data?.itemsCount ?? 0}
          onChange={(page) => {
            searchParams.set("page", page);
            setSearchParams(searchParams);
          }}
        />
        <Select
          w="auto"
          mt="1.5rem"
          defaultValue={size}
          onChange={(e) => {
            searchParams.set("size", e.target.value);
            setSearchParams(searchParams);
          }}
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
          {/* <option value="30000">2000</option> */}
        </Select>
      </Flex>
      {orders.isLoading ? (
        <Flex padding={6} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : orders.data?.itemsCount > 0 ? (
        <>
          <OrdersTable
            data={orders.data.data}
            currentStatus={currentStatus}
            page={page}
            handleUpdate={(el) => setShowUpdateOrder(el)}
            handleCancel={(el) => setShowCancelOrdersStatus(el)}
            handleOrderStatus={(el) => setShowOrderStatus(el)}
            handleSelectOrder={(el, e) => {
              if (e.target.checked) {
                setUpdateOrders([...updateOrders, el]);
              } else {
                const myOrders = updateOrders;
                const orders = myOrders.filter((o) => o._id !== el._id);
                setUpdateOrders(orders);
              }
            }}
            handleSelectAllOrders={() => {
              const allChecks = document.querySelectorAll(".order-check");
              allChecks.forEach((el) => {
                el.checked = true;
              });

              const allOrders = orders.data?.data ?? [];
              setUpdateOrders(allOrders);
            }}
          />
          <OrdersTable
            data={orders.data.data}
            showDetails={true}
            page={page}
            handleUpdate={(el) => setShowUpdateOrder(el)}
            handleOrderStatus={(el) => setShowOrderStatus(el)}
            handleSelectOrder={(el, e) => {
              if (e.target.checked) {
                setUpdateOrders([...updateOrders, el]);
              } else {
                const myOrders = updateOrders;
                const orders = myOrders.filter((o) => o._id !== el._id);
                setUpdateOrders(orders);
              }
            }}
            handleSelectAllOrders={() => {
              const allChecks = document.querySelectorAll(".order-check");
              allChecks.forEach((el) => {
                el.checked = true;
              });

              const allOrders = orders.data?.data ?? [];
              setUpdateOrders(allOrders);
            }}
            ref={fullTableRef}
            style={{ display: "none" }}
          />
        </>
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={{ base: 15, lg: 18 }}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.orders.no_orders")}
          </Text>
        </Flex>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Pagination
          page={+page > orders.data?.pages ? 1 : page}
          size={+size}
          itemsCount={orders.data?.itemsCount ?? 0}
          onChange={(page) => {
            searchParams.set("page", page);
            setSearchParams(searchParams);
          }}
        />
        <Select
          w="auto"
          mt="1.5rem"
          defaultValue={size}
          onChange={(e) => {
            searchParams.set("size", e.target.value);
            setSearchParams(searchParams);
          }}
        >
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
        </Select>
      </Flex>
      {showCreateOrder && (
        <CreateModal onClose={() => setShowCreateOrder(false)} />
      )}
      {showUpdateOrder && (
        <UpdateModal
          data={showUpdateOrder}
          onClose={() => setShowUpdateOrder(false)}
        />
      )}
      {showUpdateOrdersShippingCompany && (
        <UpdateShippingCompanyModal
          data={updateOrders}
          onClose={() => setShowUpdateOrdersShippingCompany(false)}
        />
      )}
      {showOrderStatus && (
        <OrderStatusModal
          data={showOrderStatus}
          onClose={() => setShowOrderStatus(false)}
        />
      )}
      {showUpdateOrdersStatus && (
        <UpdateOrdersStatusModal
          data={updateOrders}
          onClose={() => setShowUpdateOrdersStatus(false)}
        />
      )}
      {showCancelOrdersStatus && (
        <CancelOrdersStatusModal
          data={[showCancelOrdersStatus]}
          onClose={() => setShowCancelOrdersStatus(false)}
        />
      )}
      {showReverseOrdersStatus && (
        <UpdateOrdersStatusModal
          data={updateOrders}
          reverse={true}
          onClose={() => setShowReverseOrdersStatus(false)}
        />
      )}
    </OrdersWrapper>
  );
};

export default Orders;
