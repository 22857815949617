import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  updateProductsOrder,
} from "../../../../store/products/productsOrderSlice";

const ProductsLists = () => {
  const productsOrder = useSelector((state) => state.productsOrder);
  const [allProducts, setAllProducts] = useState([]);
  const [mostPopularProducts, setMostPopularProducts] = useState([]);
  const [mostRecentProducts, setMostRecentProducts] = useState([]);
  const toast = useToast({
    duration: 5000,
    status: "success",
    position: "top",
    isClosable: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    if (productsOrder?.data) {
      const products = productsOrder.data;
      setAllProducts(products);
      setMostPopularProducts(
        products.filter((product) => product.is_most_popular)
      );
      setMostRecentProducts(
        products.filter((product) => product.is_most_recent)
      );
    }
  }, [productsOrder?.data]);

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // Get the source and destination lists
    const sourceList = getList(source.droppableId);
    const destinationList = getList(destination.droppableId);

    if (source.droppableId === destination.droppableId) {
      // Reorder within the same list
      const reorderedList = Array.from(sourceList);
      const [movedItem] = reorderedList.splice(source.index, 1);
      reorderedList.splice(destination.index, 0, movedItem);
      setList(source.droppableId, reorderedList);
    } else {
      // Move between different lists
      const updatedSourceList = Array.from(sourceList);
      const [movedItem] = updatedSourceList.splice(source.index, 1);

      const updatedDestinationList = Array.from(destinationList);
      if (!updatedDestinationList.find((item) => item._id === movedItem._id)) {
        updatedDestinationList.splice(destination.index, 0, movedItem);
      }

      setList(source.droppableId, updatedSourceList);
      setList(destination.droppableId, updatedDestinationList);
    }
  };

  const getList = (id) => {
    if (id === "mostPopularProducts") return mostPopularProducts;
    if (id === "mostRecentProducts") return mostRecentProducts;
    return allProducts;
  };

  const setList = (id, newList) => {
    if (id === "mostPopularProducts") return setMostPopularProducts(newList);
    if (id === "mostRecentProducts") return setMostRecentProducts(newList);
  };

  const handleSaveOrder = async () => {
    const updatedProducts = allProducts.map((product) => ({
      ...product,
      is_most_popular: mostPopularProducts.some((p) => p._id === product._id),
      is_most_recent: mostRecentProducts.some((p) => p._id === product._id),
    }));
    console.log("djjjjjjjjjj");
    try {
      await dispatch(updateProductsOrder({ updatedProducts }));
      // Toast after the update is successful
      toast({
        description: "تم حفظ الترتيب بنجاح",
      });
    } catch (error) {
      console.error("Error updating order", error);
      // Optionally show an error toast
      toast({
        description: "حدث خطأ أثناء حفظ الترتيب",
        status: "error",
      });
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Button m="4" colorScheme="blue" onClick={handleSaveOrder}>
        حفظ الترتيب
      </Button>
      <Flex>
        <Droppable droppableId="allProducts">
          {(provided) => (
            <Box
              w="33%"
              p="4"
              bg="gray.100"
              borderRadius="md"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Box mb="4" fontWeight="bold">
                كل المنتجات
              </Box>
              {allProducts.map((product, index) => (
                <Draggable
                  key={product._id}
                  draggableId={product._id}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      bg="white"
                      p="2"
                      borderRadius="md"
                      shadow="md"
                      mb="2"
                      cursor="grab"
                      _hover={{ bg: "gray.200" }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {product.name}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Droppable droppableId="mostPopularProducts">
          {(provided) => (
            <Box
              w="33%"
              p="4"
              mx="2"
              bg="gray.100"
              borderRadius="md"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Box mb="4" fontWeight="bold">
                المنتجات الأكثر نشاطاً
              </Box>
              {mostPopularProducts.map((product, index) => (
                <Draggable
                  key={product._id}
                  draggableId={product._id}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      bg="white"
                      p="2"
                      borderRadius="md"
                      shadow="md"
                      mb="2"
                      cursor="grab"
                      _hover={{ bg: "gray.200" }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {product.name}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Droppable droppableId="mostRecentProducts">
          {(provided) => (
            <Box
              w="33%"
              p="4"
              bg="gray.100"
              borderRadius="md"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Box mb="4" fontWeight="bold">
                المنتجات المضاف حديثاً
              </Box>
              {mostRecentProducts.map((product, index) => (
                <Draggable
                  key={product._id}
                  draggableId={product._id}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      bg="white"
                      p="2"
                      borderRadius="md"
                      shadow="md"
                      mb="2"
                      cursor="grab"
                      _hover={{ bg: "gray.200" }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {product.name}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Flex>
    </DragDropContext>
  );
};

export default ProductsLists;
