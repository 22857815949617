import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import Search from "../../shared/search/Search";
import { getUsers } from "../../../store/users/usersSlice";
import theme from "../../global/theme";

const UserFilter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, setValue, watch } = useForm();

  watch(["admin", "from", "to"]);

  const dispatch = useDispatch();
  const admins = useSelector((state) => state.users);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter] = useState(JSON.stringify({ role: "admin", is_active: true }));

  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        let finalValues = {};
        for (let prop in values) {
          if (values[prop]) {
            if (prop === "admin") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "from" || prop === "to") {
              finalValues[prop] = new Date(values[prop]);
            } else {
              finalValues[prop] = values[prop];
            }
          }
        }
        handleFilter(finalValues);
      })}
    >
      <SimpleGrid
        columns={{ base: 2, md: 2, lg: 4 }}
        spacing={6}
        bg="#eee"
        p={2}
      >
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            (من) تاريخ الإنشاء
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.from")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("from")}
          />
        </FormControl>
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("general.to")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.to")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("to")}
          />
        </FormControl>
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            الأدمن
          </FormLabel>
          <Search
            page={page}
            itemsCount={admins.data?.itemsCount}
            data={admins.data?.data}
            prop="name"
            currentElement={getValues("admin")}
            handleChangePage={(page) => {
              setPage(page);
            }}
            handleChangeQuery={(query) => {
              setQuery(query);
            }}
            handleSelected={(item) => setValue("admin", item)}
          />
        </FormControl>
        <Flex alignItems="flex-end">
          <Button
            type="submit"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="blue"
            me={2}
          >
            فلتر
          </Button>
          <Button
            type="button"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="red"
            onClick={() => {
              handleClearFilter();
              setValue("admin", "");
              setValue("from", "");
              setValue("to", "");
            }}
          >
            استعادة
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  );
};

export default memo(UserFilter);
