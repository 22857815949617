import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightElement,
  Divider,
  Box,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import generator from "../../../../utilities/generator";

import Search from "../../../shared/search/Search";

import { createAlert, getAlerts } from "../../../../store/alerts/alertSlice";
import { getUsers } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();

  const users = useSelector((state) => state.users);
  const alerts = useSelector((state) => state.alerts);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [merchantsPage, setVendorsPage] = useState(1);
  const [merchantsQuery, setVendorsQuery] = useState("");

  useEffect(() => {
    dispatch(
      getUsers({
        page: merchantsPage,
        query: merchantsQuery,
        filter: JSON.stringify({ role: "marketer" }),
      })
    );
  }, [dispatch, merchantsPage, merchantsQuery]);

  return (
    <Modal isOpen={true} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values, event) => {
            // for (let i = 0; i < allData.length; i++) {
            //   let key = allData[i][0];
            //   if (key.startsWith("properties")) {
            //     formData.delete(key);
            //   }
            // }

            dispatch(createAlert(values))
              .unwrap()
              .then((res) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            إرسال تنبيه إلي المسوقين
          </ModalHeader>

          <ModalBody>
            {/* {alerts.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {alerts.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )} */}

            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                رسالة التنبيه
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder={"رسالة  التنبيه"}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("description")}
              />
              {errors.description?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.description.message}
                </Text>
              )}
            </FormControl>
            <FormControl mt={6}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                الرابط عند النقر
              </FormLabel>
              <Input
                type="text"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("url")}
              />
              {errors.url?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.url.message}
                </Text>
              )}
            </FormControl>

            {/* <Divider marginBlock={6} />
            {properties.length === 0 ? (
              <Button type="button" onClick={() => appendProperty({})}>
                إضافة خاصية
              </Button>
            ) : (
              <SimpleGrid
                columns={{ sm: 2, md: 3, lg: 4 }}
                border={`1px solid ${theme.border}`}
                alignItems="center"
                paddingBlock={2}
                mb={2}
                borderRadius={4}
              >
                {properties.map((el, index) => (
                  <React.Fragment key={el.id}>
                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="text"
                        minW="200px"
                        {...register(`properties.${index}.key`)}
                        placeholder="مثال: لون احمر مقاس لارج"
                      />
                    </Box>
                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="text"
                        minW="200px"
                        {...register(`properties.${index}.value`)}
                        placeholder="اكتب كمية مثلا: 5"
                      />
                    </Box>
                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="text"
                        minW="200px"
                        {...register(`properties.${index}.min`)}
                        placeholder="الحد الأدني للتنبيه"
                      />
                    </Box>
                    <Box marginInline={5} mb={3}>
                      <Button
                        type="button"
                        bg={theme.success}
                        color={theme.light}
                        onClick={() => appendProperty({})}
                        me={2}
                      >
                        <FiIcons.FiPlus />
                      </Button>
                      <Button
                        type="button"
                        bg={theme.error}
                        color={theme.light}
                        onClick={() => removeProperty(index)}
                      >
                        <FiIcons.FiDelete />
                      </Button>
                    </Box>
                  </React.Fragment>
                ))}
              </SimpleGrid>
            )} */}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={alerts.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={alerts.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
